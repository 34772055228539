<template>
  <div :class="$style.root">
    <div class="mb-4 clearfix">
      <VBtn depressed @click="$router.push({name: 'elkp/ContractDetailView', params: { contract: contractId }})">
        <VIcon>
          mdi-arrow-left
        </VIcon>
        Назад к договору
      </VBtn>
    </div>
    <VCard elevation="0" class="mb-6">
      <VCardTitle v-if="contract">
        Привязка сотрудников к договору "<router-link :to="{name: 'elkp/ContractDetailView', params: { contract: contractId }}">{{ contract.number }}</router-link>"
      </VCardTitle>
      <VCardText>
        <EmployeeList
          :items="employees.items"
          :count="employees.count"
          :page="page"
          :size="size"
          :loading="loading"
          :disabled="!hasRole(['CONTRAGENT', 'CONTRACT_HOLDER'], 'elkp')"
          :selected.sync="employeesSelected"
          @click:row="({ id }) => $router.push({ name: 'elkp/EmployeeDetailView', params: { employee: id }})"
        />
      </VCardText>
      <VFooter v-if="hasRole(['CONTRAGENT', 'CONTRACT_HOLDER'], 'elkp')" app>
        <div class="py-3 grow">
          <VBtn color="primary" depressed :loading="loading" :disabled="!employeesSelected.length" @click="onBind">
            Привязать выбранных сотрудников
          </VBtn>
          <VBtn v-if="employeesSelected.length" color="secondary" depressed class="ml-4" @click="employeesSelected = []">
            Отмена
          </VBtn>
        </div>
      </VFooter>
    </VCard>
  </div>
</template>

<script>
import {debounce, get, map} from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import EmployeeList from '@/components/elkp/EmployeeList/EmployeeList';
export default {
  name: 'EmployeeLinkView',
  components: {
    EmployeeList,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object },
  },
  data() {
    return {
      employeesSelected: [],
    }
  },
  computed: {
    ...mapGetters({
      loading: 'elkp/pending',
      contract: 'elkp/contract',
      employees: 'elkp/employees',
      hasRole: 'user/hasRole',
    }),
    contractId: function() {
      return get(this.filter, '!profileId');
    },
    authorId: function() {
      return get(this.contract, 'author');
    },
  },
  methods: {
    ...mapActions({
      fetchContractDetail: 'elkp/fetchContractDetail',
      fetchEmployeeList: 'elkp/fetchEmployeeList',
      addEmployeeToContract: 'elkp/addEmployeeToContract',
    }),
    onUpdateContract: debounce(function() {
      const id = this.contractId;
      this.fetchContractDetail({ id });
    }, 500),
    onUpdateEmployees: debounce(function() {
      this.employeesSelected = [];
      const { page, size, filter } = this;
      this.fetchEmployeeList({ page, size, filter });
    }, 500),
    onBind: function() {
      const contract = this.contractId;
      const employees = map(this.employeesSelected, 'id');
      this.addEmployeeToContract({ employees, contract }).then((result) => {
        if (result) this.onUpdateEmployees();
      });
    }
  },
  watch: {
    contractId: {
      immediate: true,
      handler: function() {
        this.onUpdateContract();
      }
    },
    page: function() {
      this.onUpdateEmployees();
    },
    size: function() {
      this.onUpdateEmployees();
    },
    filter: {
      deep: true,
      immediate: true,
      handler: function() {
        this.onUpdateEmployees();
      }
    },
  },
}
</script>

<style module lang="scss">
.root {
  :global(.v-card) {
    border: 1px solid #dee2e6;
  }
}
</style>
