<template>
  <div :class="$style.root">
    <VDataTable
        :value="selected"
        :show-select="showSelect"
        :headers="[
          { text: 'ID', value: 'id', width: 100, sortable: false },
          { text: 'Имя', value: 'values.imyapersonalnye_dannye_rabotnikov.value', sortable: false },
          { text: 'Фамилия', value: 'values.familiyapersonalnye_dannye_rabotnikov.value', sortable: false },
          { text: 'Отчество', value: 'values.otchestvopersonalnye_dannye_rabotnikov.value', sortable: false },
          { text: 'Должность', value: 'values.dolzhnostpersonalnye_dannye_rabotnikov.value', sortable: false },
          { text: 'Компания', value: 'company.name', sortable: false },
          { text: isReviewer ? 'Исправленные замечания' : 'Неисправленные замечания', value: 'comments', sortable: false },
        ]"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
          itemsPerPageText: ''
        }"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"
        :item-class="({ comments }) => !!comments ? (isReviewer ? 'orange lighten-5': 'error lighten-4'): null"
        @input="(items) => $emit('update:selected', items)"
        @click:row="props => $emit('click:row', props)"
        @update:page="page => $router.push({ query: { ...$route.query, page } })"
        @update:items-per-page="size => $router.push({ query: { ...$route.query, size } })"
    >
      <template v-slot:body.prepend>
        <tr>
          <td v-if="showSelect" />
          <td v-for="key in ['id', 'imyapersonalnye_dannye_rabotnikov', 'familiyapersonalnye_dannye_rabotnikov', 'otchestvopersonalnye_dannye_rabotnikov', 'dolzhnostpersonalnye_dannye_rabotnikov', 'authorCompanyName']" :key="key">
            <VTextField
                dense
                clearable
                hide-details
                :value="$route.query[key]"
                @input="value => $router.push({ query: { ...$route.query, [key]: value } })"
            />
          </td>
          <td />
        </tr>
      </template>
    </VDataTable>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {ROLES} from '@/store/user/enums';

export default {
  name: 'EmployeeList',
  props: {
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
    disabled: { type: Boolean },
    selected: { type: Array },
  },
  data: function() {
    return {
      options: {
        page: this.page,
        itemsPerPage: this.size
      },
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'elkp');
    },
    showSelect() {
      return !!this.selected && !this.disabled;
    }
  }
}
</script>

<style module lang="scss">
.root {

}
</style>
